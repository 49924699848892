import {
  Container,
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  Button,
  Paper
} from "@mui/material";
import Carousel from "react-material-ui-carousel";
import React, { useEffect } from "react";

const logos = [
  { src: "/logos/esa.svg" }, // ESA
  { src: "/logos/nammo.svg" }, // NAMMO
  { src: "/logos/teledyne.svg" }, // TELEDYNE
  { src: "/logos/thales.webp" }, // THALES
  { src: "/logos/uklsl.webp" }, // UKLSL
  { src: "/logos/archangel.webp" }, // ARCANGEL
  { src: "/logos/geocento.png" }, // GEOCENTO
  { src: "/logos/geospatial.webp" }, // GEOSPATIAL INTELLIGENCE
  { src: "/logos/ohmspace.png" }, // OHMSPACE
  { src: "/logos/lancaster.svg" }, // LANCHASTER UNIVERSITY
  { src: "/logos/lumispace.png" }, // LUMNISPACE
  { src: "/logos/exobotics.png" }, // EXOBOTICS
];

const AdvertisePage = () => {
  useEffect(() => {
    document.body.style.backgroundColor = "#FFFF";
  }, []);

  const items = [
    {
      text: "I wish we had discovered SpaceCareers.uk sooner—I'm confident it would have saved OhmSpace significant time and money in finding the right candidates.",
      author: "Federico Romei, OhmSpace, August 2024",
    },
    {
      text: "The quality of applicants we get from SpaceCareers.uk is higher than we get from LinkedIn",
      author: "Dr Adam White, UK SME",
    },
    {
      text: "Many of our strongest applicants came via SpaceCareers.uk, and more than one third of our applications came from women",
      author: "UK University",
    },
  ];

  return (
    <Container
      maxWidth="md"
      sx={{
        textAlign: "center",
        paddingY: 4,
        backgroundColor: "#FFFF",
        color: "#2A3C75",
        boxShadow:
          "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
        marginTop: "2em",
        marginBottom: "2em"
      }}
    >
      <Typography variant="h4" gutterBottom sx={{ color: "#283E72" }}>
        Want to find the best candidates, fast?
      </Typography>

      <Box sx={{ marginBottom: 3 }}>
        <Typography>
          Advertise on <span style={{ fontWeight: 900, color: "#283E72" }}> the most popular place
          </span> for UK space jobs
        </Typography>
      </Box>

      <Grid container justifyContent="center" alignItems="center">
        <img
          src="/undraw_hire_re_gn5j.svg"
          style={{ width: 200, height: 100, marginBottom: "2em" }}
        />
      </Grid>

      <Grid container justifyContent="center" sx={{ marginBottom: 4}}>
        <Grid item xs={12} sm={4} textAlign="center">
          <Box sx={{ marginLeft: { xs: 0, sm: 0, md: '2em' } }}>
            <Typography variant="h4" sx={{ color: "#1A82C5" }}>
              12,000+
            </Typography>
            <Typography sx={{ color: "#1A82C5" }}>monthly users</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={4} textAlign="center">
          <Box>
            <Typography variant="h4" sx={{ color: "#1A82C5" }}>
              1000+
            </Typography>
            <Typography sx={{ color: "#1A82C5" }}>
              candidate profiles
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={4} textAlign="center">
          <Box sx={{ marginRight: { xs: 0, sm: 0, md: '2em' } }}>
            <Typography variant="h4" sx={{ color: "#1A82C5" }}>
              21,000+
            </Typography>
            <Typography sx={{ color: "#1A82C5" }}>
              social media followers
            </Typography>
          </Box>
        </Grid>
      </Grid>

      <Carousel
        indicators={false}
        indicatorContainerProps={{ style: { display: "none" } }}
        navButtonsAlwaysInvisible={true}
        sx={{ overflowY: "visible", overflowX: "clip", marginBottom: "2em" }}
      >
        {items.map((item, i) => (
          <Item key={i} item={item} />
        ))}
      </Carousel>

      <Box sx={{ marginBottom: "5em" }}>
        <Typography variant="h6" sx={{ color: "#283E72", marginBottom: "2em" }}>
          Used by...
        </Typography>
        <Box
          display="flex"
          flexWrap="wrap"
          justifyContent="center"
          width={{ xs: "100%", sm: "90%", md: "70%" }}
          margin="0 auto"
          sx={{ gap: 0.1 }}
        >
          {logos.map((logo, index) => (
            <Box
              key={index}
              component="img"
              src={logo.src}
              alt={`logo-${index}`}
              className={`${index === 4 ? "uklsl" : ""} ${index === 10 ? "!w-20 !h-20" : ""}`}
              sx={{
                width: { xs: "35%", sm: "25%", md: "25%" },
                height: "auto",
                margin: "auto",
              }}
            />
          ))}
        </Box>
      </Box>

      {/* New Section */}
      <Box
        sx={{
          backgroundColor: "#1A82C5",
          paddingY: 8,
          textAlign: "center",
          color: "#FFFF",
          paddingX: { xs: 2, sm: 0 },
        }}
      >
        <Typography variant="h4" marginBottom="1em">
          Access our talent network and expand your brand
        </Typography>
        <Grid container justifyContent="center">
          <Grid item xs={12} sm={8} md={8}>
            <Typography
              variant="body1"
              sx={{
                marginBottom: "1em",
              }}
            >
              We create <strong>bespoke social media posts to 20,000+ social media followers</strong>, increasing advert views by 3-4x
            </Typography>
          </Grid>
        </Grid>
        <Grid container justifyContent="center" alignItems="center">
          <img
            src="/undraw_mobile_posts_re_bpuw.svg"
            style={{ width: 150, height: 150, marginBottom: "1em" }}
            alt="social-media"
          />
        </Grid>
        <Typography variant="body1">
          Subscribers get custom social media posts to <strong>expand</strong> <strong>your brand</strong> among job seekers and the sector
        </Typography>
      </Box>

      {/* New Section */}
      <Box sx={{ paddingY: 8, textAlign: "center" }}>
        <Typography variant="h4" sx={{ marginBottom: "1em", color: "#283E72" }}>
          Instantly match with top candidates
        </Typography>
        <Grid container justifyContent="center">
          <Grid item xs={12} sm={8} md={8}>
            <Typography variant="body1" sx={{ textAlign: "center", marginBottom: "1em" }}>
              Our service <strong style={{ color: "#283E72" }}> automatically matches your job advert to </strong> <strong style={{ color: "#283E72" }}> skilled candidates </strong>            and puts your advert in front of them, finding you the best applicants, fast
            </Typography>
          </Grid>
        </Grid>
        <Grid container justifyContent="center" alignItems="center">
          <img
            src="/top-candidates.png"
            style={{ width: 250, height: 150, marginBottom: "1em" }}
            alt="top-canidates"
          />
        </Grid>
        <Typography variant="body1">
          You can also search candidate profiles and{" "}
          <strong style={{ color: "#283E72" }}>contact top</strong>{" "}
          <strong style={{ color: "#283E72" }}>talent</strong> in our candidate
          database
        </Typography>
      </Box>

      {/* New Section - Premium Adverts */}
      <Box
        sx={{
          backgroundColor: "#283E72",
          paddingY: 8,
          textAlign: "center",
          color: "#FFFF",
        }}
      >
        <Typography
          sx={{ paddingX: { xs: 4, sm: 0 } }}
          variant="h4"
        >
          Stand out from the crowd with premium
        </Typography>
        <Grid container justifyContent="center" sx={{ marginBottom: "1em" }}>
          <img
              src="/undraw_online_ad_re_ol62__3_.svg"
              style={{ width: 250, height: 150 }}
              alt="subscriptions"
            />
        </Grid>
        <Grid
          container
          spacing={4}
          justifyContent="center"
          sx={{ paddingX: { xs: 2, sm: 4 }, display: 'flex', alignItems: 'stretch' }}
        >
          <Grid item xs={12} md={5}>
            <Card sx={{ borderRadius: 4, padding: 1, height: '100%', display: 'flex', flexDirection: 'column' }}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Premium advert
                </Typography>
                <Box
                    display="flex"
                    justifyContent="center"
                >
                  <Box textAlign="left" sx={{marginLeft: "1em", paddingY: 4}}>
                    <Typography variant="body2" gutterBottom>
                      <strong>+ Single</strong> advert
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      <strong>+ Match with and email candidates</strong> in our
                      1000+ candidate database
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      <strong>+ Bespoke social media posts</strong> to our
                      21,000+ followers
                    </Typography>
                  </Box>
                </Box>
                <Typography variant="h5" textAlign="center">£199 / advert</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={5}>
            <Card sx={{borderRadius: 4, padding: 1, height: '100%', display: 'flex', flexDirection: 'column' }}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Premium subscription
                </Typography>
                <Box
                  display="flex"
                  justifyContent="center"
                >
                  <Box textAlign="left" sx={{ marginLeft: "1em" }}>
                    <Typography
                      variant="body2"
                      gutterBottom
                    >
                      <strong>+ Unlimited </strong>premium adverts
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      <strong>+ Match with and email candidates</strong> in our
                      1000+ candidate database
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      <strong>+ Bespoke social media posts</strong> to our
                      21,000+ followers
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      <strong>+ Search candidate database </strong>and contact
                      individual candidates
                    </Typography>
                  </Box>
                </Box>
                <Typography variant="h5" marginTop="0.9em" textAlign="center">
                    £499 / month
                </Typography>
                <div className={'font-bold pt-2'} >
                 Or £449 / month when billed annually
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Button
          variant="contained"
          href="/signup"
          sx={{
            marginTop: "4em",
            backgroundColor: "#EFC100",
            color: "#283E72",
            textTransform: "none",
            "&:hover": {
              backgroundColor: "#EFC100",
            },
          }}
        >
          <strong>Create account</strong>
        </Button>
      </Box>

      {/* New Section - FAQs */}
      <Box sx={{ paddingY: 4, textAlign: "center" }}>
        <Typography variant="h5" marginBottom="1em">
          FAQs
        </Typography>
        <Typography variant="body1" marginBottom="1em">
          <strong style={{ fontStyle: "italic" }}>
            How long does the advert last?
          </strong>
          <br />
          Premium adverts stay up until you've filled the position
        </Typography>
        <Typography variant="body1" marginBottom="1em">
          <strong style={{ fontStyle: "italic" }}>
            How do I access the candidate database?
          </strong>
          <br />
          Premium subscribers can access the candidate database through the
          employer <br /> dashboard
        </Typography>
        <Typography variant="body1" marginBottom="1em">
          <strong style={{ fontStyle: "italic" }}>
            Can I advertise for free?
          </strong>
          <br />
          Absolutely - get in touch and we'll set you up with an account
        </Typography>
        <Button
          variant="contained"
          href="mailto:advertise@spacecareers.uk"
          sx={{
            marginTop: 4,
            backgroundColor: "#EFC100",
            color: "#283E72",
            textTransform: "none",
            "&:hover": {
              backgroundColor: "#EFC100",
            },
          }}
        >
          <strong>Get in touch to start</strong>
        </Button>
      </Box>
    </Container>
  );
};

const Item = (props) => {
  const { item } = props;
  return (
    <Paper elevation={0} style={{ boxShadow: "none" }}>
      <Box sx={{ marginBottom: "2em" }}>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          sx={{ flexWrap: "nowrap" }}
        >
          <Grid item sx={{ marginLeft: { xs: 0, sm: 0, md: '3em' } }}>
            <img src="/comma-left.webp" style={{ width: 40, height: 40 }} />
          </Grid>
          <Grid container justifyContent="center">
            <Grid item xs={12} sm={10} md={10}>
              <Typography
                sx={{ color: "#283E72" }}
                variant="h6"
                marginBottom={2}
                align="center"
              >
                {item.text}
                <Typography
                  sx={{ color: "#283E72" }}
                  fontStyle="italic"
                  variant="h6"
                  align="center"
                >
                  {item.author}
                </Typography>
              </Typography>
            </Grid>
          </Grid>
          <Grid item sx={{ marginRight: { xs: 0, sm: 0, md: '3em' } }}>
            <img src="/comma-right.webp" style={{ width: 40, height: 40 }} />
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default AdvertisePage;
