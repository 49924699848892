import React, { useMemo, useState } from "react";
import { bindHover, bindMenu, usePopupState } from "material-ui-popup-state/hooks";
import { getAccountType, isLoggedIn } from "../auth/functions";

import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import CircularProgress from "@mui/material/CircularProgress";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import Feedback from "../common/Feedback";
import Grow from "@mui/material/Grow";
import HoverMenu from "material-ui-popup-state/HoverMenu";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Logout from "@mui/icons-material/Logout";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Notifications from "../common/Notifications";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import { NavLink as RouterLink } from "react-router-dom";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const navLinks = [
    ["Home", "/home", []],
    [
        "About",
        "/about",
        [
            ["About Us", "/about"],
            ["Contact Us", "/contact-us"],
        ],
    ],
    [
        "Jobs",
        "/jobs",
        [
            ["Early Careers", "/jobs?early_careers=true"],
            ["Beyond Early Careers", "/jobs?early_careers=false"],
        ],
    ],
    ["Skills", "/skills", []],
    [
        "Resources",
        "/careers_resources",
        [
            ["Advice & Information", "/articles/advice_and_information"],
            ["Job Profiles", "/articles/job_profiles"],
            ["Career Stories", "/articles/career_stories"],
            ["Company A-Z", "/employers"],
        ],
    ],
    ["UK Space 101", "/uk-space-101", []],
    ["Advertise", "/advertise", []],
];
const smallScreenNavLinks = [
    ...navLinks,

    ["Create a profile", "/signup", []],
    ["Login", "/login", []],
];

const ProfileListItems = ({ onClick, user, handleLogout }) => {
    const theme = useTheme();

    const highlightIfActive = ({ isActive }) => ({
        color: isActive ? theme.palette.highlight.main : "unset",
        textUnderlineOffset: "0.6em",
    });
    return (
        <>
            <Divider sx={{ marginTop: "30px" }} />
            <ListItem
                key="/profile"
                disablePadding
                component={RouterLink}
                to="/profile"
                style={highlightIfActive}
                onClick={onClick}
                end
            >
                <ListItemButton>
                    <ListItemText primary="My Profile" />
                    <Avatar
                        alt={user?.email}
                        src={user?.profile?.image}
                        sx={{
                            border: "1px solid rgba(255, 255, 255, 0.5)",
                            borderRight: "unset",
                            margin: "auto",
                            "& .MuiAvatar-img": {
                                objectFit: "contain",
                            },
                            marginRight: "12px",
                            width: 30,
                            height: 30,
                        }}
                    />
                </ListItemButton>
            </ListItem>

            <ListItem
                key="/change-password"
                disablePadding
                component={RouterLink}
                to="/change-password"
                style={highlightIfActive}
                onClick={onClick}
                end
            >
                <ListItemButton>
                    <ListItemText primary="Change Password" />
                </ListItemButton>
            </ListItem>

            <Divider />

            <ListItem
                key="logout"
                disablePadding
                onClick={() => {
                    handleLogout();
                    onClick();
                }}
            >
                <ListItemButton>
                    <ListItemText primary="Logout" />
                </ListItemButton>
            </ListItem>
        </>
    );
};

const EmployerListItems = ({ onClick, user, handleLogout }) => {
    const theme = useTheme();

    const highlightIfActive = ({ isActive }) => ({
        color: isActive ? theme.palette.highlight.main : "unset",
        textUnderlineOffset: "0.6em",
    });
    const options = useMemo(
        () => [
            ["Employer Profile", `/employers/${user.employer.id}`],
            ["Post a Job", "/jobs/new"],
            ["Change Password", "/change-password"],
        ],
        [user]
    );
    return (
        <>
            <Divider sx={{ marginTop: "30px" }} />
            <ListItem
                key="/employers/admin"
                disablePadding
                component={RouterLink}
                to="/employers/admin"
                style={highlightIfActive}
                onClick={onClick}
                end
            >
                <ListItemButton>
                    <ListItemText primary="Employer Admin" />
                    <Avatar
                        alt={user?.employer?.name}
                        src={user?.employer?.logo}
                        sx={{
                            border: "1px solid rgba(255, 255, 255, 0.5)",
                            borderRight: "unset",
                            margin: "auto",
                            "& .MuiAvatar-img": {
                                objectFit: "contain",
                            },
                            marginRight: "12px",
                            width: 30,
                            height: 30,
                        }}
                    />
                </ListItemButton>
            </ListItem>

            {options.map(([label, to]) => (
                <ListItem
                    key={label}
                    disablePadding
                    component={RouterLink}
                    to={to}
                    style={highlightIfActive}
                    onClick={onClick}
                    end
                >
                    <ListItemButton>
                        <ListItemText primary={label} />
                    </ListItemButton>
                </ListItem>
            ))}

            <Divider />

            <ListItem
                key="logout"
                disablePadding
                onClick={() => {
                    handleLogout();
                    onClick();
                }}
            >
                <ListItemButton>
                    <ListItemText primary="Logout" />
                </ListItemButton>
            </ListItem>
        </>
    );
};

const NavBar = ({ bannerNotifications, user, onLogout }) => {
    const theme = useTheme();
    const compact = useMediaQuery(theme.breakpoints.down("lg"));
    const smallScreen = useMediaQuery(theme.breakpoints.down("lg"));
    const highlightIfActive = ({ isActive }) => ({
        color: isActive ? theme.palette.highlight.main : "unset",
        textUnderlineOffset: "0.6em",
    });
    const [drawerOpen, setDrawerOpen] = React.useState(false);
    const handleOpenDrawer = React.useCallback(() => {
        setDrawerOpen(true);
    }, [setDrawerOpen]);
    const handleCloseDrawer = React.useCallback(() => {
        setDrawerOpen(false);
    }, [setDrawerOpen]);

    const candidateLoggedIn = useMemo(
        () => user && user?.profile && getAccountType() === "candidate",
        [user]
    );
    const employerLoggedIn = useMemo(
        () => user && user.employer && getAccountType() === "employer",
        [user]
    );

    return (
        <AppBar
            position="sticky"
            color="secondary"
            sx={{ borderBottom: `2px solid ${theme.palette.text.primary}` }}
            elevation={0}
        >
            <Toolbar>
                <Button className={'shrink-0 '} component={RouterLink} to="/home">
                    <img
                        src="/logos/New Logo Colour_1@4x.png"
                        alt="SCUK logo"
                        className={'object-contain'}
                        style={{
                            height: compact ? "38px" : "59px",
                            margin: compact ? 0 : "0px",
                            padding: compact ? "5px" : "15px",
                        }}
                    />
                </Button>
                <Box
                    sx={{
                        flexGrow: 1,
                        display: { xs: "flex", lg: "none" },
                        justifyContent: "flex-end",
                    }}
                >
                    <Box p={1}>
                        {candidateLoggedIn ? <Notifications /> : null}
                        <Feedback />
                    </Box>

                    <IconButton size="large" onClick={handleOpenDrawer}>
                        <MenuIcon sx={{ color: theme.palette.text.primary }} />
                    </IconButton>

                    <Drawer
                        anchor="right"
                        open={drawerOpen && smallScreen}
                        onClose={handleCloseDrawer}
                        disableScrollLock={true}
                    >
                        <List sx={{ width: "50vw" }}>
                            {smallScreenNavLinks.map((linkInfo) => (
                                <>
                                    <ListItem
                                        key={linkInfo[0]}
                                        disablePadding
                                        component={RouterLink}
                                        to={linkInfo[1]}
                                        style={highlightIfActive}
                                        onClick={handleCloseDrawer}
                                        end
                                    >
                                        <ListItemButton>
                                            <ListItemText primary={linkInfo[0]} />
                                        </ListItemButton>
                                    </ListItem>
                                    {linkInfo[2]?.map(([label, to]) => (
                                        <>
                                            <ListItem
                                                key={label}
                                                disablePadding
                                                component={RouterLink}
                                                to={to}
                                                onClick={handleCloseDrawer}
                                                end
                                                sx={{ paddingLeft: "20px" }}
                                            >
                                                <ListItemButton>
                                                    <ListItemText primary={label} />
                                                </ListItemButton>
                                            </ListItem>
                                        </>
                                    ))}
                                </>
                            ))}
                            {candidateLoggedIn ? (
                                <ProfileListItems
                                    onClick={handleCloseDrawer}
                                    user={user}
                                    handleLogout={onLogout}
                                />
                            ) : employerLoggedIn ? (
                                <EmployerListItems
                                    user={user}
                                    handleLogout={onLogout}
                                    onClick={handleCloseDrawer}
                                />
                            ) : null}
                        </List>
                    </Drawer>
                </Box>
                <Box
                    sx={{
                        flexGrow: 1,
                        display: { xs: "none", lg: "flex" },
                    }}
                >
                    <ButtonGroup
                        size="large"
                        variant="text"
                        color="secondary"
                        sx={{
                            flexGrow: 1,
                            justifyContent: "flex-end",
                            whiteSpace: "pre",
                            "& .MuiButton-root": {
                                padding: "0px 26px",
                            },
                            "& .MuiButton-root:hover": {
                                textDecoration: "underline",
                                backgroundColor: "transparent",
                            },
                            color: theme.palette.text.primary,
                            paddingRight: "50px",
                        }}
                    >
                        {navLinks.map((linkInfo) => {
                            return (
                                <CollapsibleMenu
                                    key={linkInfo[0]}
                                    sx={{
                                        my: 2,
                                        color: theme.palette.text.primary,
                                        display: "block",
                                    }}
                                    color="inherit"
                                    style={highlightIfActive}
                                    component={RouterLink}
                                    to={linkInfo[1]}
                                    end
                                    buttonLabel={linkInfo[0]}
                                >
                                    {linkInfo[2].map(([label, to]) => (
                                        <MenuItem
                                            key={label}
                                            color="inherit"
                                            component={RouterLink}
                                            to={to}
                                            underline="none"
                                            sx={{ justifyContent: "center" }}
                                        >
                                            {label}
                                        </MenuItem>
                                    ))}
                                </CollapsibleMenu>
                            );
                        })}
                    </ButtonGroup>
                    {!isLoggedIn() && (
                        <>
                            <Button
                                size="small"
                                variant="contained"
                                color="primary"
                                sx={{
                                    margin: "auto",
                                    marginRight: "16px",
                                }}
                                component={RouterLink}
                                to="/signup"
                            >
                                <RouterLink
                                    to="/signup"
                                    style={{
                                        textDecoration: "unset",
                                        padding: "0px 14px",
                                    }}
                                >
                                    Create a profile
                                </RouterLink>
                            </Button>
                            <Button
                                size="small"
                                variant="contained"
                                color="highlight"
                                sx={{
                                    margin: "auto",
                                }}
                                component={RouterLink}
                                to="/login"
                            >
                                <RouterLink
                                    to="/login"
                                    style={{
                                        textDecoration: "unset",
                                        padding: "0px 14px",
                                    }}
                                >
                                    Login
                                </RouterLink>
                            </Button>
                        </>
                    )}

                    <Box p={1} pr={2}>
                        {candidateLoggedIn ? <Notifications /> : null}
                        <Feedback />
                    </Box>

                    {candidateLoggedIn ? (
                        <ProfileLinkList user={user} handleLogout={onLogout} />
                    ) : employerLoggedIn ? (
                        <EmployerLinkList user={user} handleLogout={onLogout} />
                    ) : isLoggedIn() ? (
                        <CircularProgress
                            color="secondary"
                            sx={{ position: "absolute", right: "56px", top: "30px" }}
                        />
                    ) : null}
                </Box>
            </Toolbar>
            {Object.keys(bannerNotifications)
                .reverse()
                .map((id) =>
                    bannerNotifications[id] === undefined ? null : (
                        <BannerNotification key={id} id={id} text={bannerNotifications[id]} />
                    )
                )}
        </AppBar>
    );
};

const ProfileLinkList = ({ user, handleLogout }) => {
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const options = [
        ["My Profile", "/profile"],
        ["Change Password", "/change-password"],
    ];
    return (
        <Box sx={{ flexGrow: 0, margin: "auto", paddingRight: "24px" }}>
            <Tooltip title={user?.email}>
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar
                        alt={user?.email}
                        src={user?.profile?.image}
                        sx={{
                            border: "1px solid rgba(255, 255, 255, 0.5)",
                            borderRight: "unset",
                            margin: "auto",
                            "& .MuiAvatar-img": {
                                objectFit: "contain",
                            },
                        }}
                    />
                </IconButton>
            </Tooltip>
            <Menu
                sx={{ mt: "45px" }}
                anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
                disableScrollLock={true}
            >
                {options.map((option) => (
                    <MenuItem
                        color="inherit"
                        key={option[1]}
                        component={RouterLink}
                        to={`${option[1]}`}
                        underline="none"
                        onClick={handleCloseUserMenu}
                    >
                        {option[0]}
                    </MenuItem>
                ))}

                <Divider />

                <MenuItem
                    color="inherit"
                    key="logout"
                    underline="none"
                    onClick={() => {
                        handleLogout();
                        handleCloseUserMenu();
                    }}
                >
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>
        </Box>
    );
};

const CollapsibleMenu = ({ children, buttonLabel, ...props }) => {
    const theme = useTheme();
    const popupState = usePopupState({ variant: "popover", popupId: { buttonLabel } });

    return (
        <>
            <Button
                sx={{ my: 2, color: theme.palette.text.primary }}
                color="inherit"
                component={RouterLink}
                {...bindHover(popupState)}
                {...props}
            >
                {buttonLabel}
            </Button>
            {!!children.length && (
                <HoverMenu
                    {...bindMenu(popupState)}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                    }}
                    onClick={popupState.close}
                    disableScrollLock
                >
                    {children}
                </HoverMenu>
            )}
        </>
    );
};

const EmployerLinkList = ({ user, handleLogout }) => {
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const options = useMemo(
        () => [
            ["Employer Admin", "/employers/admin"],
            ["Employer Profile", `/employers/${user.employer.id}`],
            ["Post a Job", "/jobs/new"],
            ["Change Password", "/change-password"],
        ],
        [user]
    );

    return (
        <Box sx={{ flexGrow: 0, margin: "auto", paddingRight: "24px" }}>
            <Tooltip title={user?.employer?.name}>
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar
                        alt={user?.employer?.name}
                        src={user?.employer?.logo}
                        sx={{
                            border: "1px solid rgba(255, 255, 255, 0.5)",
                            borderRight: "unset",
                            margin: "auto",
                            "& .MuiAvatar-img": {
                                objectFit: "contain",
                            },
                        }}
                    />
                </IconButton>
            </Tooltip>
            <Menu
                sx={{ mt: "45px" }}
                anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
                disableScrollLock={true}
            >
                {options.map((option) => (
                    <MenuItem
                        color="inherit"
                        key={option[1]}
                        component={RouterLink}
                        to={`${option[1]}`}
                        underline="none"
                        onClick={handleCloseUserMenu}
                    >
                        {option[0]}
                    </MenuItem>
                ))}

                <Divider />

                <MenuItem
                    color="inherit"
                    key="logout"
                    underline="none"
                    onClick={() => {
                        handleLogout();
                        handleCloseUserMenu();
                    }}
                >
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>
        </Box>
    );
};

const BannerNotification = ({ text, id }) => {
    const theme = useTheme();
    return (
        <Paper
            square
            key={id}
            sx={{
                marginTop: "1px",
                padding: "4px 20px",
                width: "calc(100% - 40px)",
                backgroundColor: theme.palette.text.primary,
                color: theme.palette.secondary.main,
            }}
            color="primary"
        >
            {typeof text === "string" ? <Typography align="center">{text}</Typography> : text}
        </Paper>
    );
};

export default NavBar;
