import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import PageContainer from "../common/PageContainer";
import Paper from "@mui/material/Paper";
import { Trans } from "react-i18next";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

const team = [
    {
        name: "Ewan Wright",
        title: "Director",
        image: "/team/Ewan.jpg",
        bio: "Ewan first volunteered with SpaceCareers.uk in 2018, and now helps direct it. He is currently also studying for a PhD at the University of British Columbia, Vancouver, researching the risks of space debris reentries. Outside of work he enjoys going up and down mountains in both the UK and Canada (and anywhere else).",
    },
    {
        name: "Jacob Smith",
        title: "Director",
        image: "/team/Jacob.jpg",
        bio: "Passionate about improving access to the space sector, Jacob is a former Chair of UKSEDS and also the founder of SpaceProjects.uk. He has worked in engineering, training, and coordination roles across the sector and enjoys learning new things, the great outdoors, and live music.",
    },
    {
        name: "Joanne Oliver",
        title: "Director",
        image: "/team/Joanne.jpg",
        bio: "Curious, analytical and adventurous, Joanne is passionate about helping others find their place within the space industry and identifying how SpaceCareers.uk can support the growth of the sector for decades to come. She enjoys learning, psychology, reading and being in the mountains.",
    },
    {
        name: "Rachel Gould",
        title: "Team Lead",
        image: "/team/Rachel.JPG",
        bio: "Rachel is a Systems Engineer at AAC Clyde Space and a Prospero Space Fellow. She is passionate about increasing the diversity of people and skills within the space sector. Outside of work she fills her free time with books, art and triathlon.",
    }, 
    {
        name: "Shifa Shaikh",
        title: "Vice-Team Lead",
        image: "/team/Shifa.jpg",
        bio: "Modern day version of a nerd, Shifa is a Space Engineer working in the Aerospace sector. When she’s not working or volunteering, you will either find her nose deep in a thriller novel or baking. She also enjoys talking about Space Sustainability, literature and stargazing.",
    },   
    {
        name: "Shanen Butler",
        title: "Content Writer",
        image: "/team/Shanen.jpg",
        bio: "Shanen is an Astrophysics student at the University of Hull with a keen interest in science communication and Equality, diversity and inclusion, studying Philosophy on the side of her degree. Always open to new concepts and ideas!",
    },
    {
        name: "Vito Latorre",
        title: "Operations Coordinator",
        image: "/team/Vito.jpg",
        bio: "Vito is passionate about space exploration and soon to start a PhD in astrobiology. As Operations Coordinator for SpaceCareers.uk, he empowers individuals to pursue space careers. He’s also a dedicated public speaker, sharing the wonders of space science with audiences of all ages.",
    },
    {
        name: "Jack Crane",
        title: "Web Developer",
        image: "/team/Jack.jpg",
        bio: "Jack has always wanted to be an astronaut and that developed their love for space and rockets. They are currently a 4th year Aeronautics student at Imperial and want to continue on to a PhD in space technology.",
    },
    {
        name: "Derek Lam",
        title: "Operations Coordinator",
        image: "/team/Derek.jpg",
        bio: "Hello, my name is Derek. I'm an Operations Coordinator for Spacecareers. I have a big passion to work in the space industry in the future and will likely retrain and do a Masters though currently my job is in management consultancy. I live in Bristol and outside of work I like playing chess, learning Korean, badminton and a lil' bit of gym.",
    },
];

const AboutUsPage = () => {
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const imageSize = {
        xs: "100px",
        sm: "126px",
    };
    const teamBoxSize = "240px";
    return (
        <PageContainer maxWidth="md">
            <Paper
                sx={{
                    padding: { sm: "20px 50px", xs: "20px 20px" },
                    paddingBottom: 0,
                }}
            >
                <Typography variant="h3" paragraph color="text" textAlign="center" mb={5}>
                    About Us
                </Typography>
                <Box mb={2}>
                    <Grid container spacing={3}>
                        <Grid item md={12} xs={12}>
                            <Typography
                                variant="body"
                                paragraph
                                color="text"
                                sx={{ whiteSpace: "pre-line" }}
                            >
                                <Trans i18nKey="about.why_body" />
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
                <Grid container mb={5} mt={3} spacing={2}>
                    <Grid item xs={6} md={3}>
                        <Typography variant="h5" color="primary" textAlign="center" paragraph>
                            Connecting
                        </Typography>

                        <Box
                            sx={{
                                backgroundImage: `url(/connecting.png)`,
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                                height: imageSize,
                                width: imageSize,

                                margin: "auto",
                                marginTop: "10px",
                                marginBottom: "10px",
                            }}
                        />
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <Typography variant="h5" color="primary" textAlign="center" paragraph>
                            Educating
                        </Typography>

                        <Box
                            sx={{
                                backgroundImage: `url(/educating.png)`,
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                                height: imageSize,
                                width: imageSize,

                                margin: "auto",
                                marginTop: "10px",
                                marginBottom: { md: "20px", xs: "30px" },
                            }}
                        />
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <Typography variant="h5" color="primary" textAlign="center">
                            Supporting
                        </Typography>

                        <Box
                            sx={{
                                backgroundImage: `url(/supporting.png)`,
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                                height: imageSize,
                                width: imageSize,

                                margin: "auto",
                                marginTop: "10px",
                                marginBottom: "10px",
                            }}
                        />
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <Typography variant="h5" color="primary" textAlign="center">
                            Advocating
                        </Typography>

                        <Box
                            sx={{
                                backgroundImage: `url(/advocating.png)`,
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                                height: imageSize,
                                width: imageSize,

                                margin: "auto",
                                marginTop: "10px",
                                marginBottom: "10px",
                            }}
                        />
                    </Grid>
                </Grid>
                <Box px={{ md: 4, sm: 0, xs: 2 }}>
                    <Typography
                        variant="h4"
                        paragraph
                        color="primary"
                        textAlign="center"
                        mt={6}
                        mb={3}
                    >
                        Helping you find your place in space
                    </Typography>
                    <Typography
                        variant="body"
                        paragraph
                        color="text"
                        sx={{ whiteSpace: "pre-line" }}
                    >
                        <Trans i18nKey="about.helping_body" />
                    </Typography>
                </Box>
                <Box
                    sx={{
                        backgroundColor: theme.palette.text.primary,
                        "& a": {
                            color: theme.palette.secondary.main,
                        },
                        margin: { sm: "30px -50px", xs: "30px -20px" },
                    }}
                >
                    <Grid container>
                        <Grid item md={6} xs={12} p={{ xs: 5, sm: 6, md: 5 }}>
                            <Typography variant="h4" paragraph color="secondary" textAlign="center">
                                Our Journey
                            </Typography>
                            <Typography
                                variant="body"
                                paragraph
                                color="secondary"
                                sx={{
                                    whiteSpace: "pre-line",
                                }}
                            >
                                <Trans i18nKey="about.journey_body" components={{ a: <a /> }} />
                            </Typography>
                        </Grid>

                        <Grid item md={6} xs={12}>
                            <Box
                                sx={{
                                    backgroundImage: `url(/sc-presentation-photo.jpg)`,
                                    backgroundSize: "cover",
                                    backgroundRepeat: "no-repeat",
                                    height: "100%",
                                    width: "100%",
                                    minHeight: "260px",
                                    backgroundPositionX: { md: "-128px", xs: "unset" },
                                    backgroundPositionY: { md: "center", xs: "center" },
                                }}
                            />
                        </Grid>
                    </Grid>
                </Box>
                <Typography variant="h4" paragraph color="text" textAlign="center" mt={4}>
                    Our Values
                </Typography>
                <Grid container mt={2} px={{ md: 4, xs: 0 }} spacing={5}>
                    <Grid item sm={8} xs={12}>
                        <Typography variant="h5" color="primary" textAlign="left" paragraph>
                            Accessibility
                        </Typography>
                        <Typography variant="body" color="text" textAlign="left" paragraph>
                            {t("about.accessibility_body")}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        sm={4}
                        xs={0}
                        sx={{
                            alignSelf: "center",
                            display: { xs: "none", sm: "block" },
                        }}
                    >
                        <Box
                            sx={{
                                backgroundImage: `url(/accessibility.png)`,
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                                backgroundPositionY: "center",
                                height: "126px",
                                maxWidth: "126px",

                                margin: "auto",
                                marginTop: "10px",
                                marginBottom: "10px",
                            }}
                        />
                    </Grid>
                    <Grid
                        item
                        sm={4}
                        xs={0}
                        sx={{
                            alignSelf: "center",
                            display: { xs: "none", sm: "block" },
                        }}
                    >
                        <Box
                            sx={{
                                backgroundImage: `url(/openness.png)`,
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                                backgroundPositionY: "center",
                                height: "100px",
                                maxWidth: "180px",

                                margin: "auto",
                                marginTop: "10px",
                                marginBottom: "10px",
                            }}
                        />
                    </Grid>
                    <Grid item sm={8} xs={12}>
                        <Typography
                            variant="h5"
                            color="primary"
                            textAlign={{ sm: "right", xs: "left" }}
                            paragraph
                        >
                            Openness and Collaboration
                        </Typography>
                        <Typography
                            variant="body"
                            color="text"
                            textAlign={{ sm: "right", xs: "left" }}
                            paragraph
                        >
                            {t("about.openness_body")}
                        </Typography>
                    </Grid>
                    <Grid item sm={8} xs={12}>
                        <Typography variant="h5" color="primary" textAlign="left" paragraph>
                            Commitment and Quality
                        </Typography>
                        <Typography variant="body" color="text" textAlign="left" paragraph>
                            {t("about.commitment_body")}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        sm={4}
                        xs={0}
                        sx={{
                            alignSelf: "center",
                            display: { xs: "none", sm: "block" },
                        }}
                    >
                        <Box
                            sx={{
                                backgroundImage: `url(/quality.png)`,
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                                backgroundPositionY: "center",
                                height: "116px",
                                maxWidth: "116px",

                                margin: "auto",
                                marginTop: "10px",
                                marginBottom: "10px",
                            }}
                        />
                    </Grid>
                </Grid>

                <Box
                    sx={{
                        backgroundColor: theme.palette.primary.main,
                        margin: { sm: "20px -50px 0px -50px", xs: "20px -20px 0px -20px" },
                    }}
                    p={5}
                >
                    <Typography variant="h4" paragraph color="secondary" textAlign="center">
                        Our Team
                    </Typography>
                    <Typography
                        variant="body"
                        color="secondary"
                        textAlign="center"
                        paragraph
                        mb={3}
                    >
                        {t("about.team_body")}
                    </Typography>
                    <Divider color="secondary" />
                    <Grid container spacing={2} mt={2}>
                        {team.map((teamMember) => (
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                md={4}
                                sx={{
                                    display: "flex",
                                    position: "relative",
                                    justifyContent: "center",

                                    "&:hover .MuiBox-root.text": {
                                        height: teamBoxSize,
                                        backgroundColor: "rgba(150,150,150,0.8)",
                                    },
                                    "& .bio": {
                                        display: "none",
                                    },
                                    "&:hover .summary": {
                                        display: "none",
                                    },
                                    "&:hover .bio": {
                                        display: "block",
                                    },
                                }}
                            >
                                <Box
                                    sx={{
                                        backgroundPosition: "center",
                                        backgroundImage: `url(${teamMember.image})`,
                                        backgroundSize: "cover",
                                        backgroundRepeat: "no-repeat",
                                        height: teamBoxSize,
                                        width: teamBoxSize,

                                        margin: "auto",
                                        marginTop: "10px",
                                        marginBottom: "10px",
                                    }}
                                />
                                <Box
                                    className="text"
                                    sx={{
                                        position: "absolute",
                                        bottom: 0,
                                        margin: "10px",
                                        width: `calc(${teamBoxSize} - 30px)`,
                                        backgroundColor: "rgba(215,215,215,0.8)",
                                        fontSize: "1.2rem",
                                        padding: "0px 15px",
                                    }}
                                >
                                    <Typography
                                        color={theme.palette.text.primary}
                                        className="summary"
                                    >
                                        {teamMember.name}
                                    </Typography>
                                    <Typography
                                        color={theme.palette.primary.main}
                                        className="summary"
                                    >
                                        {teamMember.title}
                                    </Typography>
                                    <Typography
                                        color={theme.palette.secondary.main}
                                        className="bio"
                                        fontSize="0.75rem"
                                        p={1}
                                    >
                                        {teamMember.bio}
                                    </Typography>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Paper>
        </PageContainer>
    );
};

export default AboutUsPage;
